<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- <b-row>
          <b-col cols="12" md="5" sm="12" class="mb-md-0 mb-2">
            <label>Employee</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="userHashid"
              :value="userHashid"
              @input="(val) => $emit('update:userHashid', val)"
              :options="employeeListOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Employee.."
            />
          </b-col>

          <b-col cols="12" md="2" sm="6" class="mb-md-0 mb-2">
            <label>Month</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="month"
              :clearable="false"
              :options="months"
              label="name"
              class="w-100"
              :reduce="(val) => val.number"
              placeholder="Select Months.."
            />
          </b-col>
          <b-col cols="12" md="2" sm="6" class="mb-md-0 mb-2">
            <label>Year</label>
            <b-form-spinbutton
              v-model="year"
              min="2020"
              :max="new Date().getFullYear()"
            />
          </b-col>
          <b-col cols="12" md="3" sm="6">
            <label>Export Filtered Data </label>
            <br />
            <b-button
              variant="info"
              class="mb-0 mr-1"
              @click="attendanceExport"
            >
              <feather-icon icon="DownloadIcon" class="mr-50" />
              <span class="align-middle">Attendance Report</span>
            </b-button>
          </b-col>
        </b-row> -->

        <!-- Table Top -->
        <b-row class="mt-1">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search Name"
              /> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :fields="columns"
        ref="refUserListTable"
        class="position-relative"
        :items="leaveData"
        fixed responsive
        primary-key="id"
        empty-text="No matching records found"
        striped
        style="max-height:600px"
        :sticky-header="true"
      >

      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'name' ? '180px' : '100px' }"
        >
      </template>
        <!-- <template #cell()="data">
          <p v-if="data.value == '-:-'">{{ data.value }}</p>
          <b v-else style="color:red;">{{ data.value }}</b>
        </template> -->

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-link
        v-if="$can('Zircly Admin')"
        :to="{
          name: 'view-attendance-muster',
          params: { id: data.item.hashid },
        }"
        class="font-weight-bold d-inline-block text-nowrap"
          >
        {{ data.item.name }}
          </b-link>
          <span v-else>
        {{ data.item.name }}
          </span>
        </template>


        <!-- Reduce column size for number named columns -->
        <template #cell()="data">
          <span class="d-inline-block text-truncate" style="max-width: 100px;">
            <p v-if="data.value == '-:-' || data.value == '-' ">{{ data.value }}</p>
            <b v-else style="color:red;">{{ data.value }}</b>
          </span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAttendanceMusterList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import attendanceMusterStoreModule from "../attendanceMusterStoreModule";
import useAttendanceMusterList from "./useAttendanceMusterList";
import axios from "@axios";

let months = require("/src/libs/months-detailed.json");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-attendance_musters";

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        attendanceMusterStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    let {
      fetchAttendanceMusterList,
      tableColumns,
      perPage,
      currentPage,
      totalAttendanceMusterList,
      attendanceMustersMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByAttendanceMusterUrgencyStatus,
      filterByAttendanceMusterType,
      resolveUserStatusVariant,
      attendanceMusterStatusOption,
      userHashid,
      month,
      year,
    } = useAttendanceMusterList();

    return {
      // Sidebar
      fetchAttendanceMusterList,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalAttendanceMusterList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByAttendanceMusterUrgencyStatus,
      filterByAttendanceMusterType,
      resolveUserStatusVariant,
      attendanceMusterStatusOption,
      userHashid,
      month,
      year,
      tableColumns,
    };
  },
  data() {
    return {
      employeeListOptions: [],
      months: months,
      leaveData : [],
      columns : [
          { key: "name", sortable: false, label: "Name" },
          { key: "1", sortable: false, label: "1" },
          { key: "2", sortable: false, label: "2" },
          { key: "3", sortable: false, label: "3" },
          { key: "4", sortable: false, label: "4" },
          { key: "5", sortable: false, label: "5" },
          { key: "6", sortable: false, label: "6" },
          { key: "7", sortable: false, label: "7" },
          { key: "8", sortable: false, label: "8" },
          { key: "9", sortable: false, label: "9" },
          { key: "10", sortable: false, label: "10" },
          { key: "11", sortable: false, label: "11" },
          { key: "12", sortable: false, label: "12" },
          { key: "13", sortable: false, label: "13" },
          { key: "14", sortable: false, label: "14" },
          { key: "15", sortable: false, label: "15" },
          { key: "16", sortable: false, label: "16" },
          { key: "17", sortable: false, label: "17" },
          { key: "18", sortable: false, label: "18" },
          { key: "19", sortable: false, label: "19" },
          { key: "20", sortable: false, label: "20" },
          { key: "21", sortable: false, label: "21" },
          { key: "22", sortable: false, label: "22" },
          { key: "23", sortable: false, label: "23" },
          { key: "24", sortable: false, label: "24" },
          { key: "25", sortable: false, label: "25" },
          { key: "26", sortable: false, label: "26" },
          { key: "27", sortable: false, label: "27" },
          { key: "28", sortable: false, label: "28" },
          { key: "29", sortable: false, label: "29" },
          { key: "30", sortable: false, label: "30" },
          { key: "31", sortable: false, label: "31" },
        ]
    };
  },
  created() {
    this.fetchEmployeeList();
    this.fetchLeavesData();
  },
  methods: {
    
    fetchLeavesData() {
      axios.get("get-team-upcoming-leaves").then((response) => {
        this.leaveData = response.data.data;
        if(this.leaveData.length > 0){
          ///skip hashid and date_of joining

            var temp = Object.keys(this.leaveData[0])
            .filter((key) => key !== "hashid" && key !== "date_of_join" && key !== "name")
            .map((key) => ({ key: key, sortable: false, label: key }));
            console.log(temp);
          this.columns = [{ key: "name", sortable: false, label: "Name" }];
          this.columns = this.columns.concat(temp);
          console.log(this.columns);
        }
      });
    },
    fetchEmployeeList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "fetch Employee List failed.",
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    editRecord(data) {
      this.$router
        .push(`/edit/attendance_muster/${data.item.hashid}`)
        .catch(() => {});
    },
    viewRecord(data) {
      this.$router
        .push(`/view/attendance_muster/${data.item.hashid}`)
        .catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.title}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    refetchAttendanceMustersData() {
      console.log("Added Attendance_muster");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-attendance_musters/removeAttendanceMuster", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Attendance_muster Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Attendance_muster Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Attendance_muster Deleted",
                icon: 'BellIcon',
                variant: 'success',
                text: "Attendance_muster Deleted Successfully.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Attendance_muster Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Attendance_muster Deletion failed.",
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attendance_muster Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Attendance_muster Deletion failed.`,
              icon:'BellIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
    attendanceExport() {
      axios
        .get("/attendance-export", {
          params: { user: this.userHashid, month: this.month, year: this.year },
          responseType: "blob",
        })

        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileNameHeader = "x-suggested-filename";
          const suggestedFileName = response.headers[fileNameHeader];
          const effectiveFileName =
            suggestedFileName === undefined
              ? "Attendance_Report-" + this.month + "-" + this.year + ".xls"
              : suggestedFileName;
          console.log(
            `Received header [${fileNameHeader}]: ${suggestedFileName}, effective fileName: ${effectiveFileName}`
          );

          link.setAttribute("download", effectiveFileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      // .then((response) => {
      //   if(response.data.success){
      //     console.log(response.data.data);
      //   }else{
      //   this.$toast.error(response.data.message);
      //   }
      // });
    },
  },
  computed:{
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@media (min-width: 768px) {
  .table-responsive,
  .vgt-responsive {
    overflow: auto;
  }
}

.table-responsive,
.vgt-responsive {
  overflow-y: auto !important;
}
</style>
